import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Stack } from '@patreon/studio';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "stele-aware"
    }}>{`Stele Aware`}</h1>
    <h2 {...{
      "id": "statically-known-strings"
    }}>{`Statically known strings`}</h2>
    <p>{`The basis for modern i18n are what Stele calls statically known strings. A
statically known string is a string where no run time information is required to
know the entire contents of the sentence.`}</p>
    <p>{`Here is an example of a statically known string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const message = 'Hi there Ben!'
`}</code></pre>
    <p>{`Here is an example of a not statically known string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const message = 'Hi there ' + getName()
`}</code></pre>
    <p>{`The process of making your application `}<em parentName="p">{`Stele Aware`}</em>{` therefore is the process of
converting your application to using only statically known strings.`}</p>
    <h2 {...{
      "id": "what-is-run-time-vs-compile-time"
    }}>{`What is run time vs compile time?`}</h2>
    <p>{`How could you know by looking at a line of code whether the whole string is
statically known or not? This is a surprisingly difficult question to answer.
Knowing the answer is buried in a knowledge of ASTs, but to keep it high level
something is statically known to stele if it comprised only of:`}</p>
    <h3 {...{
      "id": "jsx"
    }}>{`JSX`}</h3>
    <ul>
      <li parentName="ul">{`Literals`}<ul parentName="li">
          <li parentName="ul">{`String literal: `}<inlineCode parentName="li">{`'Hi!'`}</inlineCode></li>
          <li parentName="ul">{`Number literal: 1`}</li>
        </ul></li>
      <li parentName="ul">{`Identifiers `}<inlineCode parentName="li">{`myValue`}</inlineCode><ul parentName="li">
          <li parentName="ul">{`Identifiers are essentially variables.`}</li>
        </ul></li>
      <li parentName="ul">{`MemberExpressions `}<inlineCode parentName="li">{`props.myValue`}</inlineCode><ul parentName="li">
          <li parentName="ul">{`MemberExpressions are essentially identifiers made of objects.`}</li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components"
        }}>{`Stele Components`}</a></li>
    </ul>
    <h3 {...{
      "id": "translate"
    }}>{`Translate`}</h3>
    <p>{`The second argument to the translate function is more strict. It can only be
comprised of a string literal which is an ICU string.`}</p>
    <h3 {...{
      "id": "stele-aware-guides"
    }}>{`Stele aware guides`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/how-stele-works"
        }}>{`Simple Strings`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/how-to/simple"
        }}>{`Simple Strings`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/how-to/development"
        }}>{`Check if translated`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/how-to/ternaries_and_switches"
        }}>{`Ternaries and Selects`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/how-to/plurals"
        }}>{`Plurals`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/how-to/numbers"
        }}>{`Numbers and Dates`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      